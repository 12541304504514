<template>
    <admin-dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="leads"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.name="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col @click="handleClickName(item)">
                        {{ item.name }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }} <!--TODO add day.js and default format -->
            </template>
            <template v-slot:item.actions="{ item }">
                <lead-delete-dialog @deleted="handleRemoveLead" :lead="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import Lead from '../../../models/Lead';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import LeadDeleteDialog from "../../../components/dialogs/LeadDeleteDialog";


export default {
    name: "leads-index",
    components: {AdminDashboardLayout,LeadDeleteDialog},
    data: function () {
        return {
            loading: false,
            leads: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                {text: 'Phone', value: 'phone'},
                {text: 'Time To Call', value: 'time_call'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        handleClickName(lead) {
            this.$router.push({name: 'admin.leads.show', params: {id: lead.id}})
        },
        handleRemoveLead(id) {
            this.leads = this.$_.filter(this.leads, lead => lead.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.leads = await Lead.get()
        this.loading = false
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}
</style>
